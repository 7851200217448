import { useEffect, useState } from 'react';

interface Result<T> {
	loading: boolean;
	data?: T;
	error?: Error;
}

export default function useFetch<InputType, ReturnType>(
	url: string,
	config: RequestInit,
	handler: (result: Result<ReturnType>) => void
): (data: InputType) => void {
	const [data, setData] = useState();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();

	useEffect(() => {
		handler({
			data,
			loading,
			error
		});
	}, [data, loading, error, handler]);

	return async data => {
		setData(undefined);
		setError(undefined);
		setLoading(true);

		try {
			const res = await (
				await fetch(url, { ...config, body: JSON.stringify(data) })
			).json();

			setData(res);
			setLoading(false);
		} catch (error) {
			setError(error);
			setLoading(false);
		}
	};
}
