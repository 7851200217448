import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Contact from './views/Contact';
import Index from './views/Index';
import Services from './views/Services';
import Privacy from './views/Privacy';

import Header from './components/Header';
import Footer from './components/Footer';

import './sass/App.scss';

function App() {
	return (
		<div id="app" className="text-center">
			<div className="cover-container d-flex w-100 p-3 mx-auto flex-column">
				<Router>
					<Header />

					<Switch>
						<Route path="/contact">
							<Contact />
						</Route>

						<Route path="/services">
							<Services />
						</Route>

						<Route path="/privacy">
							<Privacy />
						</Route>

						<Route path="/">
							<Index />
						</Route>
					</Switch>

					<Footer />
				</Router>
			</div>
		</div>
	);
}

export default App;
