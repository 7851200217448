import React from 'react';

export default function Footer() {
	return (
		<footer className="mastfoot mt-auto">
			<div className="inner">
				<p>&copy; 2020 BuildByDev</p>
			</div>
		</footer>
	);
}
