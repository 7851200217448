import React from 'react';
import NavLink from './NavLink';

export default function Header() {
	return (
		<header className="masthead mb-auto">
			<div className="inner">
				<h3 className="masthead-brand">BuildByDev</h3>

				<nav className="nav nav-masthead justify-content-center">
					<NavLink label="Home" path="/" />

					<NavLink label="Services" path="/services" />

					<NavLink label="Contact" path="/contact" />
				</nav>
			</div>
		</header>
	);
}
