import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface NavLinkProps {
	label: string;
	path: string;
}

export default function NavLink({ label, path }: NavLinkProps) {
	const location = useLocation();

	const isActive = location.pathname === path;

	return (
		<Link to={path} className={`nav-link${isActive ? ' active' : ''}`}>
			{label}
		</Link>
	);
}
