import React from 'react';

interface InputGroupProps {
	handler: (value: string) => void;
	validator?: (value: string) => boolean;
	value: string;
	type: 'text' | 'email';
	name: string;
	label: string;
	required: boolean;
	placeholder?: string;
	help?: string;
}

export default function InputGroup({
	handler,
	validator,
	value,
	type,
	name,
	label,
	required,
	placeholder,
	help
}: InputGroupProps) {
	return (
		<div className="form-group">
			<label htmlFor={name}>{label}</label>

			<input
				type={type}
				className="form-control"
				onChange={e => {
					if (validator) {
						if (validator(e.target.value)) {
							handler(e.target.value);
						}
					} else {
						handler(e.target.value);
					}
				}}
				name={name}
				id={name}
				required={required}
				placeholder={placeholder}
				value={value}
				aria-describedby={
					help !== undefined ? name + 'Help' : undefined
				}
			/>

			{help && (
				<small id={name + 'Help'} className="form-text text-muted">
					{help}
				</small>
			)}
		</div>
	);
}
