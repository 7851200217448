import React, { useState } from 'react';

import useFetch from '../hooks/useFetch';

import InputGroup from '../components/InputGroup';

export default function Contact() {
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');

	const [reset, setReset] = useState(false);
	const [success, setSuccess] = useState<boolean | null>(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<Error | undefined>();

	const fetch = useFetch<
		{
			name: string;
			phone: string;
			email: string;
			message: string;
			subject: string;
		},
		{ success: boolean }
	>('/.netlify/functions/contact', { method: 'POST' }, res => {
		if (res.error) {
			setError(res.error);
		}

		setLoading(res.loading);

		if (res.data) {
			setSuccess(res.data.success);

			if (res.data.success && !reset) {
				setName('');
				setPhone('');
				setEmail('');
				setMessage('');
				setReset(true);
			}
		}
	});

	return (
		<div className="view contact">
			<h1>Contact</h1>

			{error && <div className="alert alert-danger">{error.message}</div>}

			{success !== null &&
				(success ? (
					<div className="alert alert-success">Message sent!</div>
				) : (
					<div className="alert alert-danger">
						We couldn't send your message. Please try again later.
					</div>
				))}

			<form
				onSubmit={e => {
					e.preventDefault();
					console.group('contact form submitted');

					console.log('name', name);
					console.log('email', email);
					console.log('message', message);
					console.log('phone', phone);

					console.log('fetching...');

					fetch({
						name,
						phone,
						email,
						message,
						subject: 'placeholder'
					});
					setReset(false);

					console.groupEnd();
				}}
			>
				<InputGroup
					value={name}
					handler={setName}
					type="text"
					name="name"
					label="Name"
					placeholder="Enter name"
					required
				/>

				<InputGroup
					value={phone}
					handler={setPhone}
					validator={val => val.match(/^[0-9]{0,11}$/) !== null}
					type="text"
					name="phone"
					label="Phone number"
					placeholder="Enter phone"
					required
				/>

				<InputGroup
					value={email}
					handler={setEmail}
					type="email"
					name="email"
					label="Email address"
					placeholder="Enter email"
					help="We'll never share your email or phone number with anyone else."
					required
				/>

				<div className="form-group">
					<label htmlFor="message">Message</label>

					<textarea
						className="form-control"
						name="message"
						id="message"
						rows={6}
						value={message}
						onChange={e => {
							console.log(e);
							setMessage(e.target.value);
						}}
						required
					></textarea>
				</div>

				<div className="mx-auto">
					<button
						type="submit"
						className="btn btn-primary btn-block"
						disabled={loading}
					>
						Submit
					</button>
				</div>
			</form>
		</div>
	);
}
