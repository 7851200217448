import React from 'react';
import { Link } from 'react-router-dom';

export default function Index() {
	return (
		<main role="main" className="view index innner cover">
			<h1 className="cover-heading">Cover your page.</h1>

			<p className="lead">
				Cover is a one-page template for building simple and beautiful
				home pages. Download, edit the text, and add your own fullscreen
				background photo to make it your own.
			</p>

			<p className="lead">
				<Link to="/services" className="btn btn-lg btn-secondary">
					Learn more
				</Link>
			</p>
		</main>
	);
}
